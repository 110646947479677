import React from 'react';
import Lane from '../lib/Lane';
import Display from '../lib/Display';
import Request from '../lib/Request';
import Endpoints from '../lib/Endpoints';

export default class AdminChooseActions extends React.Component {

    async startTraining() {
        const validatePromise = await Request.put(Endpoints.startTraining(this.props.app.reservationNumber), {});
        return validatePromise;
    }

    async completeTraining() {
        const validatePromise = await Request.put(Endpoints.completeTraining(this.props.app.reservationNumber), {});
        return validatePromise;
    }

    setAction = () => {
        if (!this.props.app.startedTraining) {
            // return Lane.toggleStartedTraining(true)
            this.startTraining().then(response => {
                if (response.status === 200 || response.status === 304) {
                    Lane.toggleStartedTraining(true);
                    this.completeTraining().then(response => {
                        if (response.status === 200 || response.status === 304) {
                            Lane.toggleCompletedTraining(true);
                        } else {
                            alert('There was a problem completing the training');
                        }
                    });
                } else {
                    alert('There was a problem starting the training');
                }
            });
        } else {
            Display.toggleModalKeypad(true);
            // return Lane.toggleCompletedTraining(true)
        }
    }

    buttonText = () => {
        if (!this.props.app.startedTraining) {
            return 'Start Training';
        } else {
            return 'Start Game Play';
        }
    }

    render() {
        return (
            <div id="choose-actions" className={`button-stack button-stack-single ${this.props.display.showAdminChooseActions ? "active" : ""}`}>
                <div className="btn btn-gold" onClick={this.setAction}>{this.buttonText()}</div>
            </div>
        )
    }
}
